// Videos
// video compersser https://www.veed.io/    https://www.veed.io/tools/video-compressor    https://www.veed.io/edit/eec7094a-0df2-4eab-9e9b-c9158084548a/compress?source=%2Ftools%2Fvideo-compressor
import physicsVideo from "../Images/physics_veed.mp4"
import moleculesVideo from "../Images/molecules_veed.mp4"
import musclesVideo from "../Images/muscles_webm.webm"
import wordsVideo from "../Images/words_cut2_veed.mp4"

// Tile Images
import physicsTile from "../Images/physicsTile.png"
import moleculesTile from "../Images/moleculesTile.png"
import musclesTile from "../Images/musclesTile.png"
import wordsTile from "../Images/wordsTile.png"
import earlyCTile from "../Images/earlyCTall.jpg"

// Video Covers
import physicsVideoCover from "../Images/physicsVideoPoster.png"
import moleculesVideoCover from "../Images/singingMoleculesVideoCover.png"
import musclesVideoCover from "../Images/musclesVideoCover.png"
import wordsVideoCover from "../Images/wordsVideoCover.png"
import earlyCVideoCover from "../Images/earlyCWide.jpg"


export const allResearchData = {

    Konstantin: {
        title: "Singing Molecules",
        videoUrl: moleculesVideo,
        videoPoster: moleculesVideoCover,
        tilePoster: moleculesTile,
        descriptionShort: "Professor Konstantin Vodopyanov's research deciphers molecular vibrations, impacting fields from chemistry to medicine through 'molecular melodies'.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | February 7, 2022",
        content: (
            <> 
                <div className='eventsPageTitle'>The Melody of Molecules with Konstantin Vodopyanov</div>
                <h3>What is the significance of molecular vibrations?</h3>
                <p>Each molecule in our universe is in constant motion, vibrating in a unique pattern. These vibrations are not just random movements; they are like a signature tune of the molecule. By studying these vibrations, we can gain insights into the molecular structure and properties, leading to advancements in various scientific fields.</p>
                <h3>How do molecular vibrations relate to electromagnetic waves?</h3>
                <p>When molecules vibrate, they emit electromagnetic waves. These waves are essentially the 'songs' of molecules. This phenomenon is crucial in spectroscopy, where we analyze the spectrum of light absorbed and emitted by substances. It allows us to identify and understand the composition and behavior of different molecules.</p>
                <h3>Why is this concept important in the real world?</h3>
                <p>Understanding molecular vibrations and the resulting electromagnetic waves has practical applications in numerous areas, from identifying unknown substances in chemistry to developing new pharmaceuticals in medicine. It's a fundamental concept that helps us decode the molecular world, leading to innovations and discoveries that enhance our understanding of everything from basic chemistry to complex biological processes.</p>
            </>
        )
    },
    // "Enrique-Del-Barco": {
    //     title: "Quantum Physics for the Future",
    //     videoUrl: physicsVideo,
    //     videoPoster: physicsVideoCover,
    //     tilePoster: physicsTile,
    //     descriptionShort: "Professor of Physics Enrique Del Barco’s research examines potential developments for more efficient technology that uses less energy.",
    //     authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | April 26, 2022",
    //     content: (
    //         <>
    //             <div className='eventsPageTitle'>Physics Research with Enrique Del Barco</div>
    //             <h3>Why are you interested in this research?</h3>
    //             <p>Understanding how the microscopic world functions is almost bucolic, as the laws governing this world (quantum mechanics) are absolutely unimaginable from our classical world perspective but explain the most fundamental phenomena with unnumerable repercussions in our day-to-day lives.</p>
    //             <h3 >Who inspires you to conduct your research?</h3>
    //             <p>My students. I reflect myself in my students, from high school to the Ph.D. level. They remind me of my youngest self, when I looked at the world with amusement and was looking to understand how everything works. I see this in my students’ faces when they are in the lab trying to unveil the next secret of the microscopic world.</p>
    //             <h3>Why is this research important?</h3>
    //             <p>Our research in nanoscale spintronics has strong potential to represent a breakthrough in technology. To provide an example, spintronics-based circuitry may end consuming one thousand times less energy than the most advanced electronic technology. Only this would represent a revolution, as currently energy consumption by electronic circuits (including computers) represents one of the most important expenses of energy in the world, contributing significantly to our climate change. Decreasing this by a thousand would be amazing!</p>
    //         </>
    //     )
    // },
    // This one is about early childhood development and psychology, she is a PHD recipient and the youngest member of the faculty. She is doing cutting edge researcy. Make it sound regal and posh like at a fancy university.  
    Fendley: {
        title: "Pioneering Research with Dr. Fendley",
        videoUrl: "<not found>",
        videoNotClickable: true,
        views: "1.8M",
        videoPoster: earlyCVideoCover,
        tilePoster: earlyCTile,
        descriptionShort: "Dr. Fendley, the youngest faculty member and a distinguished PhD recipient, leads groundbreaking research pushing boundries in the field of early childhood development and psychology.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | September 12th, 2024",
        content: (
            <>
                <div className='eventsPageTitle'>Advancing Frontiers of Childhood Development with Dr. Fendley, PhD</div>
                {/* <h3>What drives your groundbreaking research in early childhood development?</h3>
                <p>As one of the youngest and most distinguished members of the faculty, my passion lies in the formative years of childhood, where the foundations of cognition, behavior, and emotional resilience are laid. My research delves into the intricate processes of psychological development, aiming to foster environments where every child can thrive. By exploring these critical developmental stages, I seek to illuminate how we can optimize learning and emotional well-being from the earliest years.</p>
                <h3>How does your research influence modern educational practices?</h3>
                <p>Through my work, I aim to reshape how we approach childhood education by integrating cutting-edge psychological theories into everyday practices. My research focuses on neuroplasticity during early years, allowing educators and policymakers to tailor interventions that enhance cognitive growth and emotional intelligence, creating a more robust and nurturing educational landscape for young learners.</p>
                <h3>What do you aspire to achieve through your research?</h3>
                <p>My ultimate vision is to redefine early childhood education and psychological support, ensuring that every child, regardless of background, has the opportunity to reach their fullest potential. By advancing our understanding of developmental psychology, I strive to influence global educational systems, fostering a new generation of emotionally resilient and intellectually curious individuals, prepared to face the challenges of tomorrow's world.</p>


 */}

<p>
        Because of her age it was a split decision when the college board voted to accept her into a staff position. She was almost not admitted, but once she was accepted she quickly built a reputation with her influential research on early childhood motivation and development. Just 2 years after joining staff the same board unanimously voted her MVP of the department and most likely to make a meaningful difference in a child's life. 
    </p>

    <p>
        Her "circuits" based approach to learning combined with her studies and research in motivation have allowed her to create a system of deliberately designed games that build key circuits of neurons for skills that are highly correlated to success and happiness later in life such as creativity, conversational skills, information assimilation, working memory (WM), and many others. The games build a relationship and understanding between the players (usually a parent or teacher and the child), and because of the game based approach and positive reinforcement children enjoy playing them and so are intrinsically motivated.
    </p>
    <p>
        A few quotes from a recent interview with a local news channel:
    </p>

    <p>
        Q:
    </p>
    <p>
        So what do you mean by neuron circuits?
    </p>
    <p>
        A:
    </p>
    <p>
        "...just like an artificial neural net training pathways that allow it to do amazing things a child's brain is a biological neural net building pathways as they develop. We actually keep building and changing these pathways throughout our lives, but during critical periods in a child's development they are genetically predisposed to be able to learn certain skills. Just like how language is much easier to learn at a certain age so are many other things. There are even some things that can only be learned at a certain age for example perfect pitch. If we teach our children how to think they'll be the ones that come up with solutions for the things we can't, cure the diseases we will face in our old age, and come up with creative solutions to the big picture problems were leaving them with"
    </p>
    <p>
        "...There are circuits of neurons that lead from one are of the brain to other areas and they do this amazing job of turning raw stimulus into a meaningful understanding and reaction. Rods and cones in the eyes convert light into impulses, that go through an incredibly complex web of neurons and lead to a cognition where we have decided what is going on in front of us, and then from there a signal may be sent to an emotional center that affects our behavior. The whole process is fascinating. We have seen some very interesting studies recently that use an fMRI to see the actual areas in the brain that light up differently when different emotions are felt. 
    <p> 
    </p>
        One of the most important reasons to study emotion is to teach empathy, so we can understand what others are feeling and what they would feel in different situations, which inspired one of my games. So this one is about emotional awareness. The instructor asks the child to look at random people in a public place or or in a tv show and decide what their feeling and why, and maybe even a story of what they're doing and why. After that they ask them what they think would make the person feel a certain way like happy, or scared, or surprised. Its always ok to encourage them to be silly in these fictional back stories. This builds emotional awareness of themselves and others and also fosters creativity."   

    </p>

    <p>
        We've summarized a few of the other games she mentioned in the interview:

    </p>

    <p>
        This one is about free associations and builds conversation stills. Person A chooses a word. Person B lists 3 things that that word reminds them of, and why, and then tells a story about one of the 3 things they listed. Person A lists 3 things that the story reminded them of, and why, and then tells a story about one of the 3 things they listed. It goes back and fourth like this for as long as they want to play or until they get to some set number of rounds. Its best to keep it light and fun and its a great way to tell stories to a child that they might not have heard otherwise. To add a little challenge each player can try to remember all of the words leading up to that point and the first player that can't list them all looses. Sometimes its nice to let the younger player win and make a big deal of it when they're feeling a little less motivated. This game can be played with two people, or more, or even solo. 
    </p>

    <p>
        In another game a child can be taught a few mnemonic techniques used by memory athletes like the links system, the story method, and the method of loci. Participants are given ideas one by one. They then create a memorable object for each idea, preferably something absurd or exaggerated to enhance memorability. They then connect each new object to the previous ones through a sequence of actions or a story. To further organize and recall the series, these objects can be grouped together and placed along a familiar route or path. They feel like they are just playing a fun creativity game but what they are really doing is building a pathway what sets their default way of assimilating information to a mnemonic technique used by "memory athletes" who can remember a whole deck of cards in a sitting. That's not the end result here, its more important to be fun and silly and go with the flow, but it helps set them up to have more doors open to them later in life. 
    </p>
 

    <p>
        "The best feeling is when two children spontaneously start to play these games together, because that means they really do enjoy them and they can keep developing even when there isn't an instructor available to work with them"
    </p>

    <p>
        Her contributions are greatly appreciated and the staff here at Whalley are certainly happy to have her in the department, and are excited to see what she does in the future.
    </p>






            </>
        )
    },
    Harmon: {
        title: "Slowing Aging & Resistance Training",
        videoUrl: musclesVideo,
        videoPoster: musclesVideoCover,
        tilePoster: musclesTile,
        descriptionShort: "Dr. Kylie Harmon's research innovates aging muscle health through strength training, aiming to improve life quality for the elderly.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | November 22, 2021",
        content: (
            <>
                <div className='eventsPageTitle'>Advancing Muscle Health with Kylie Harmon, PhD</div>
                <h3>What motivates your research on muscle aging and neuromuscular plasticity?</h3>
                <p>As we age, our muscles naturally lose strength and flexibility, impacting overall health and quality of life. My research is driven by the desire to understand and counteract these changes. By exploring how strength training influences muscle aging and neuromuscular plasticity, I aim to contribute to healthier, more active aging for everyone.</p>
                <h3>How does your research impact strength training routines?</h3>
                <p>My work involves developing targeted strength training programs that are specifically designed to slow muscle aging and enhance neuromuscular adaptability. These programs are not just for athletes but are tailored to individuals of various ages and health backgrounds, providing valuable insights for healthcare professionals in creating effective and personalized training routines.</p>
                <h3>What do you hope to achieve with your research?</h3>
                <p>The ultimate goal of my research is to revolutionize how we approach muscle health as we age. By providing evidence-based strength training methodologies, I aim to assist healthcare professionals in devising routines that not only maintain muscle strength but also improve neuromuscular responsiveness. This, in turn, can lead to prolonged independence and a better quality of life for the elderly population.</p>
            </>
        )
    },
    Sellnow: {
        title: "The Right Words At The Right Time",
        videoUrl: wordsVideo,
        videoPoster: wordsVideoCover,
        tilePoster: wordsTile,
        descriptionShort: "Professor Timothy Sellnow's research focuses on crisis communication, developing strategies to effectively manage emergencies and influence public perception during critical moments.",
        authorLine: "By Nicole Dudenhoefer | Video by Whalley Social | January 28, 2022",
        content: (
            <>
                <div className='eventsPageTitle'>Q&A with Professor Timothy Sellnow</div>
                <h3>Why are you passionate about crisis communication?</h3>
                <p>Communication during a crisis isn't just about conveying information; it's about shaping perceptions and influencing behavior in critical moments. The right words can calm fears, provide clarity, and foster trust. My passion lies in understanding how strategic communication can mitigate the impact of crises and aid in effective management and resolution.</p>
                <h3>How does your research contribute to this field?</h3>
                <p>My research revolves around creating, testing, and refining messages to identify the most effective language during different stages of a crisis. By analyzing various communication strategies and their outcomes, we can develop guidelines that help organizations and authorities respond more effectively during emergencies, ultimately saving lives and resources.</p>
                <h3>What impact do you hope your research will have?</h3>
                <p>I aspire to see my research being applied in real-world scenarios, contributing to more efficient crisis management. By equipping communicators with evidence-based strategies, I hope to enhance the ability of organizations and governments to respond to crises with messages that not only inform but also reassure and guide the public during times of uncertainty and distress.</p>                
            </>
        )
    },
}