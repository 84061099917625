import { Link } from 'react-router-dom'
import React from 'react'
import "./Tile.css"

function Tile({image, title, title2, description, link, customClass}) {

  return (
    <div className={'tile '+(customClass ? customClass : "")}>
        <div className='tileImage'>
            <img src={image}></img>
        </div>
        {title && <div className='titleFont padTop10'>{title}</div>}
        {title2 && <div className='title'>{title}</div>}
        <div className='tileDescription' style={{paddingBottom: link ? "60px":"", maxHeight: "100px", overflow: 'hidden'}}>
          <pre>
            {description}
          </pre>
        </div>
        {link &&
            <Link to={link}>
                <button className='readMoreButton'>Read More</button>
            </Link>
        }
    </div>
  )
}

export default Tile