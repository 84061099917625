import React, { useEffect, useState } from 'react'
import a from "../../Images/globe.jpg"
import { Link, useParams } from 'react-router-dom'
import VideoPlayer from './VideoPlayer'
import Tile from '../../Components/Tile/Tile'
import "./FacultyResearch.css"
import { scrollTop } from '../../Global/Functions'
import { allResearchData } from '../../Data/Articles'

function FacultyResearch() {
  
    let {researchDataKey} = useParams()
    console.log("researchDataKey", researchDataKey)

    const [researchData, setResearchData] = useState()



    useEffect(()=>{
        getResearchData()
        scrollTop()
    },[researchDataKey])
    // If there is a key get the story at that key, if there is no key or there is no story at that key display faculty-research home page content
    function getResearchData(){
        let tempResearchData = allResearchData[researchDataKey]
        if(tempResearchData)
            setResearchData(tempResearchData)
        else
            setResearchData()
    }
  
    function showResearchTile(index){
        if(index < (Object.entries(allResearchData).length - 1))
            return true
        else if(!researchData) 
            return true
        return false
    }

    return (
    <div className='pageInnerWidth page left'>
        {researchData ?
            <>
                
                {/* <div className='pageTitle pageTitleUnderline facultyResearchTitle'>Faculty Research</div> */}
                <div className='center'>
                    <div className='pageTitle pageTitleUnderline facultyResearchTitle2'>
                        {researchData?.title}
                    <div className='facultyResearchAuthorLine'>{researchData?.authorLine}</div>
                    </div>

                    <div>
                        <VideoPlayer videoUrl={researchData?.videoUrl} posterUrl={researchData?.videoPoster} videoNotClickable={researchData.videoNotClickable} views={researchData.views}></VideoPlayer>
                    </div>
                
                </div>
                <div>
                    {researchData?.content}
                </div>

                <div className='tilesContainer'>

                    <div className='pageTitle left pageTitleUnderline facultyResearchTitle'>Other Articles</div>

                    {Object.entries(allResearchData).map(([key, data], index) => (
                        <>
                            {(researchDataKey !== key) && 
                            <Tile
                                title={data.title}
                                image={data.tilePoster}
                                link={"/faculty-research/"+key}
                                description={data.descriptionShort}
                                // customClass={index == (Object.entries(allResearchData).length - 1) ? " sometimesHiddenTile":""}
                            >
                            </Tile>
                        }
                        </>
                    ))}
                </div>
            </>
            :
            <>
                <div className='pageTitle pageTitleUnderline facultyResearchTitle'>Faculty Research</div>
                <h2>Exploring the Frontiers of Science and Technology</h2>
                <p>Welcome to our Faculty Research page! Here, we delve into the groundbreaking research conducted by our esteemed faculty members. Our research spans a wide range of disciplines, from advanced materials science to cutting-edge biotechnology, offering insights into the latest scientific developments and technological innovations.</p>
                <p>Our faculty comprises world-renowned scientists and researchers dedicated to pushing the boundaries of knowledge. They work tirelessly to uncover new scientific truths, develop innovative technologies, and address some of the most pressing challenges facing our world today. Their research not only contributes to academic excellence but also has far-reaching implications for industry and society.</p>
                <p>Collaboration is at the heart of our research ethos. We believe in the power of interdisciplinary approaches, bringing together experts from diverse fields to foster creative solutions and new perspectives. This collaborative spirit extends beyond our campus, as we engage with international research institutions, industry partners, and government agencies to maximize the impact of our work.</p>
                <p>Our research initiatives are also deeply committed to educating and inspiring the next generation of scientists and innovators. Through mentorship, hands-on research opportunities, and cutting-edge coursework, our faculty play a pivotal role in shaping the minds that will lead us into the future. Explore our page to learn more about the exciting research projects and the brilliant minds behind them.</p>
                <div className='tilesContainer'>
                    
                    <div className='pageTitle left pageTitleUnderline facultyResearchTitle'>Top Faculty Research Articles</div>

                    {Object.entries(allResearchData).map(([key, data], index) => (
                        <Tile
                            title={data.title}
                            image={data.tilePoster}
                            link={"/faculty-research/"+key}
                            description={data.descriptionShort}
                            customClass={index == (Object.entries(allResearchData).length - 1) ? " sometimesHiddenTile":""}
                        >
                        </Tile>
                    ))}
                </div>
            </>
        }
    </div>
  )
}

export default FacultyResearch