import React, { useState } from 'react'
import whalleyLogo from "../../Images/Whalley_Logo_For_Circle_BG.png"
import playButton from "../../Images/playButton.png"
import youTubeLogoLetters from "../../Images/YouTube_dark_logoW.png"
import "./VideoPlayer.css"
import { Link } from 'react-router-dom'

function VideoPlayer({videoUrl, posterUrl, videoNotClickable, views}) { 

    const [videoStarted, setVideoStarted] = useState(false)
    const [showMiddleButton, setShowMiddleButton] = useState(true)
    function startPlaying(){
        if(videoNotClickable) return
        setVideoStarted(true)
        setShowMiddleButton(false)
        document.getElementById("videoPlayer")?.play()
    }

  return (
    <div>
        {/* <div>Video Player</div> */}
        {/* {videoUrl} */}
        <div className='videoPlayerContainer'>
            <Link to="https://www.youtube.com/@whalley" target='_blank'>
                <div className={'videoPlayerTopLeft '+(videoStarted?"videoPlayerTopLeftClosed":"")}>
                    <img src={whalleyLogo}></img>
                    <div className='videoPlayerTopLeftText'>
                        <div className='videoPlayerTopLeftChannelName'>Whalley University</div>
                        <div className='videoPlayerTopLeftSubs'>12.4K subscribers</div>
                    </div>
                </div>
            </Link>
            {!videoStarted &&
                <Link to={"https://www.youtube.com/@whalley"} target='_blank'> 
                    <div className='bottomLeftSquare'>
                        <img src={youTubeLogoLetters}></img>
                        <div style={{display: "inline-block", color: "white", fontWeight: "500", fontSize: "12px"}}>
                            {views && views + " views"}
                        </div>
                    </div>
                </Link>
            }
            {showMiddleButton &&
                <div className='videpPlayerPlayButton' onClick={startPlaying}>
                    <img src={playButton}></img>
                </div>
            }
            <video src={videoUrl} controls={videoStarted} id='videoPlayer' poster={posterUrl} className='videoPlayer' style={{}}>
            {/* <video src={videoUrl} controls={videoStarted} id='videoPlayer' poster={posterUrl} className='videoPlayer' style={{height: "450px", width: "800px", objectFit: "cover", objectPosition: "top"}}> */}
            {/* <video width="750" height="425" src={videoUrl} controls={false} id='videoPlayer' poster={posterUrl}> */}
            </video>
        </div>
    </div>
  )
}

export default VideoPlayer