import { Link } from 'react-router-dom'
import React from 'react'
import "./Tile.css"
import isMobile from 'is-mobile';

function WideTile({image, title, title2, description, link, customClass}) {

    // const isMobile = isMobile()

  return ( 
    <div className={'wideTile '+(customClass ? customClass : "")}>
        <img src={image} className={'wideTileImage '+(isMobile()?" wideTileImageMobile":"")}></img>
        <div className={'wideTileText '+(isMobile()?" wideTileTextMobile":"")}>
            {title && <div className='wideTileTitle'>{title}</div>}
            {title2 && <div className='tileTitle2'>{title}</div>}
            <pre>
                {description}
            </pre>
        </div>
        {link &&
            <Link to={link}>
                <button className={'wideTileReadMoreButton '+(isMobile()?" wideTileReadMoreButtonMobile":"")}>Read More</button>
            </Link>
        }
    </div>
  )
}

export default WideTile